.logo{
    width: 50px;
    
}
.logo-text{
    color: #03B1EE;
    font-size: large;
}

.text-color {
    color: #03B1EE;
}


.navbar-dark .navbar-brand {
    color: #03B1EE;
}

    .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
        color: #03B1EE;
    }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #03B1EE;
}

header {
    position: relative;
    background-color: black;
    width: 100%;
    overflow: hidden;
}

header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

header .container {
    position: relative;
    z-index: 2;
}

header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
}